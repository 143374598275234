import { getDataMapper, mapArray, mapField, parseISO } from "@/shared";

export const mapReportPaymentFromServer = getDataMapper({
    date: mapField("datetime", parseISO),
    paymentSum: mapField("sum", Number),
    paymentType: mapField("payment_type", String),
    order: mapField(
        "order",
        getDataMapper({
            id: mapField("id", Number),
            number: mapField("number", Number),
            type: mapField("type", String),
        })
    ),
    admin: mapField("fio", String),
    pointTitle: mapField("point_title", String),
});

export const mapReportPaymentsFromServer = mapArray(mapReportPaymentFromServer);
