import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { createPaymentType } from "../api/createPaymentType";
import { deletePaymentType } from "../api/deletePaymentType";
import { deletePaymentTypes } from "../api/deletePaymentTypes";
import { editPaymentType } from "../api/editPaymentType";
import { getPaymentType } from "../api/getPaymentType";
import { getPaymentTypes } from "../api/getPaymentTypes";

export const usePaymentsStore = defineStore("payments", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: createPaymentType,
        getEntity: getPaymentType,
        getEntities: getPaymentTypes,
        deleteEntity: deletePaymentType,
        deleteEntities: deletePaymentTypes,
        editEntity: editPaymentType,
    });

    const {
        selectMeta,
        selectData,
        isSelectLoading,
        selectPrevPaginationParams,
        fetchSelect,
    } = useSelectEntityStore({
        getEntities: getPaymentTypes,
    });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        getOne,
        editOne,
        addOne,
        setManyLoading,

        // select
        selectData,
        selectMeta,
        isSelectLoading,
        selectPrevPaginationParams,
        fetchSelect,
    };
});
