// TODO: убрать отключение этого правила во всем проекте, когда обновят внутреннюю зависимость
//  eslint-plugin-node (https://github.com/eslint-community/eslint-plugin-n/pull/13)
/* eslint-disable node/no-unsupported-features/es-syntax */
// import * as Sentry from "@sentry/vue";
import { createPinia } from "pinia";
import { createApp } from "vue";

import { takeJwtFromUrl } from "./lib/takeJwtFromUrl";
import { withI18n } from "./providers/withI18n";
import { withRouter } from "./providers/withRouter";
import App from "./ui/App.vue";

import "./styles/main.scss";

takeJwtFromUrl();

const app = createApp(App);

// Sentry init
/* Sentry.init({
    app,
    dsn: "https://5c74265387bd9db18b4796e4f8e6054a@sentry.rentinhand.ru/5",
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}); */

app.use(createPinia());
withRouter(app);
withI18n(app);

export const initApplication = async () => {
    if (Number(import.meta.env.VITE_SHOULD_ENABLE_MOCKS)) {
        const { faker } = await import("@faker-js/faker");

        faker.setLocale("ru");

        const { worker, onUnhandledRequest } = await import("./mocks/browser");

        await worker.start({ onUnhandledRequest });
    }

    app.mount("#app");
};
