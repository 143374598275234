import { makeHttpRequest } from "@/shared";

import { mapPaymentTypeFromServer } from "./mappers/mapPaymentTypesFromServer";

export const createPaymentType = async (paymentType) => {
    const response = (
        await makeHttpRequest({
            url: "/payment-type",
            method: "POST",
            data: paymentType,
        })
    ).data;

    return mapPaymentTypeFromServer(response.data);
};
