import { mapField, mapArray, getDataMapper, parseISO } from "@/shared";

export const mapInventoryPaybackFromServer = getDataMapper({
    categoryTitle: mapField("category_title", String),
    countRent: mapField("count_rent", Number),
    income: mapField("income", Number),
    inventoryTitle: mapField("inventory_title", String),
    inventoryId: mapField("inventory_id", Number),
    paybackPoint: mapField("payback_point", parseISO),
    pointTitle: mapField("point_title", String),
    buyPrice: mapField("buy_price", Number),
    buyDate: mapField("buy_date", parseISO),
    paybackRentCount: mapField("payback_rent_count", Number),
});

export const mapInventoryPaybacksFromServer = mapArray(
    mapInventoryPaybackFromServer
);
