import { getDataMapper, mapField, isPrimitive, isNil } from "@/shared";

export const mapProductsIncomeFiltersToServer = getDataMapper({
    period_from: mapField("period", (period) => {
        return isPrimitive(period.interval) || isNil(period.interval)
            ? null
            : period.interval.from;
    }),
    period_to: mapField("period", (period) => {
        return isPrimitive(period.interval) || isNil(period.interval)
            ? null
            : period.interval.to;
    }),
    period_interval: mapField("period", (period) => {
        return isPrimitive(period.interval) ? period.interval : null;
    }),
    product_point_id: mapField("pointId", Number),
    category_id: mapField("categoryId", Number),
});
