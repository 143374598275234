import { getDataMapper, mapField } from "@/shared";

import { mapCategoriesIncome } from "./mapDashboardStartBusinessFromServer";

const mapTopProduct = getDataMapper({
    id: mapField("product_id", Number),
    title: mapField("title", String),
    avatar: mapField("avatar", String),
    sum: mapField("sum", Number),
    countDeals: mapField("count_deals", Number),
});

export const mapDashboardStockFromServer = getDataMapper({
    income: mapField("income", {
        sumTotal: mapField("sum_total", Number),
    }),
    middleReceipt: mapField("middle_receipt", Number),
    countContracts: mapField("count_contracts", Number),
    topProduct: mapField("top_product", mapTopProduct),
    graph: mapField("graph"),
    categoriesIncome: mapField("categories_income", mapCategoriesIncome),
});
