import httpInstance, { token } from "./config";

const makeHttpRequest = ({
    url = "/",
    method = "get",
    params = {},
    data = {},
    headers = {},
    responseType = "json",
    signal,
}) => {
    if (token) headers.authorization = `Bearer ${token}`;

    return httpInstance.request({
        url,
        method,
        headers,
        params,
        data,
        responseType,
        signal,
    });
};

export default makeHttpRequest;
