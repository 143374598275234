import { getDataMapper, mapField } from "@/shared";

const mapDashboardIncome = getDataMapper({
    income: mapField("income", {
        sumTotal: mapField("sum_total", Number),
    }),
    count: mapField("count", Number),
});

export const mapDashboardExpenseType = getDataMapper({
    expenseTypeId: mapField("expense_type_id", Number),
    expenseType: mapField("expense_type", String),
    expenseSum: mapField("expense_sum", Number),
    percent: mapField("percent", Number),
});

export const mapDashboardAttraction = getDataMapper({
    attractionId: mapField("attraction_id", Number),
    attractionTitle: mapField("attraction_title", String),
    countClients: mapField("count_clients", Number),
    percent: mapField("percent", Number),
});

export const mapIncomeRentState = getDataMapper({
    stateId: mapField("state_id", Number),
    stateTitle: mapField("state_title", String),
    sum: mapField("sum", Number),
    count: mapField("count", Number),
});

export const mapInventoryState = getDataMapper({
    stateId: mapField("state_id", Number),
    stateTitle: mapField("state_title", String),
    countInventory: mapField("count_inventory", Number),
});

export const mapIncomeRepairState = getDataMapper({
    stateId: mapField("state_id", Number),
    stateTitle: mapField("state_title", String),
    sum: mapField("sum", Number),
    count: mapField("count", Number),
});

export const mapDashboardMainFromServer = getDataMapper({
    income: mapField("income", {
        sumTotal: mapField("sum_total", Number),
        sumClean: mapField("sum_clean", Number),
    }),
    expense: mapField("expense", Number),
    clientCount: mapField("client_count", {
        all: mapField("all", Number),
        blackList: mapField("black_list", Number),
    }),
    countContracts: mapField("count_contracts", Number),
    incomeSeparately: mapField("income_separately", {
        rent: mapField("rent", mapDashboardIncome),
        stock: mapField("stock", mapDashboardIncome),
        repair: mapField("repair", mapDashboardIncome),
    }),
    middleReceipts: mapField("middle_receipts", {
        full: mapField("full", Number),
        rents: mapField("rent", Number),
        stock: mapField("stock", Number),
        repair: mapField("repair", Number),
    }),
    graph: mapField("graph"),
    expenseTypes: mapField("expense_types", mapDashboardExpenseType),
    attractions: mapField("attractions", mapDashboardAttraction),
    incomeRentStates: mapField("income_rent_states", mapIncomeRentState),
    inventoryStates: mapField("inventory_states", {
        states: mapField("states", mapInventoryState),
        noPointsCount: mapField("no_points_count", Number),
    }),
    incomeRepairStates: mapField("income_repair_states", mapIncomeRepairState),
    sumDebts: mapField("sum_debts", Number),
});
