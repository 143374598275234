import {
    useUserStore,
    useTariffStore,
    useSettingsStore,
    mapSettingsFromServer,
    mapIntegrationsStatusesFromServer,
    useIntegrationsStore,
} from "@/entities";
import { isArray, isNil } from "@/shared";
import { useSidebarStore } from "@/widgets";

import { getWorkspaceSettings } from "../api/getWorkspaceSettings.js";

export const initWorkspace = async function () {
    const workspaceSettings = await getWorkspaceSettings();

    const userStore = useUserStore();
    const tariffStore = useTariffStore();
    const sidebarStore = useSidebarStore();
    const settingsStore = useSettingsStore();
    const integrationsStore = useIntegrationsStore();

    if (isNil(workspaceSettings)) return;

    if (!isNil(workspaceSettings.profile)) {
        useUserStore().setHumanId(workspaceSettings.profile.id);
    }

    if (!isNil(workspaceSettings.profile_id)) {
        userStore.setProfileId(workspaceSettings.profile_id);
    }

    if (!isNil(workspaceSettings.role)) {
        if (workspaceSettings.role.is_admin) {
            location.replace(`https://${import.meta.env.VITE_APP_ADMIN}`);
        }

        userStore.setIsDirector(workspaceSettings.role.is_director);
    }

    if (!isNil(workspaceSettings.user)) {
        userStore.setUserCredentials(workspaceSettings.user);
    }

    if (isArray(workspaceSettings.menu)) {
        sidebarStore.setMenuLinks(workspaceSettings.menu);
    }

    if (!isNil(workspaceSettings.settings)) {
        settingsStore.setSettings(
            mapSettingsFromServer(workspaceSettings.settings)
        );
    }

    /* if (!isNil(workspaceSettings.tariff)) {
        useTariffStore().setTariff(
            mapHumanTariffFromServer(workspaceSettings.tariff)
        );
    } */

    /* if (!isNil(workspaceSettings.statistics)) {
        useTariffStore().setStatistic(
            mapTariffStatisticFromServer(workspaceSettings.statistics)
        );
    } */

    if (!isNil(workspaceSettings.integrations)) {
        integrationsStore.setIntegrations(
            mapIntegrationsStatusesFromServer(workspaceSettings.integrations)
        );
    }

    if (!isNil(workspaceSettings.token)) {
        settingsStore.setToken(workspaceSettings.token);
    }

    await tariffStore.setTariff();

    userStore.loadRights();
};
