import {
    getDataMapper,
    mapField,
    // composeFields,
    formatISO,
    getObjectId,
} from "@/shared";

export const mapRentFiltersToServer = getDataMapper({
    /* time: composeFields(["dateFrom", "dateTo"], ([dateFrom, dateTo]) => {
        return {
            start_from: dateFrom ? formatISO(dateFrom) : null,
            edn_before: dateTo ? formatISO(dateTo) : null,
        };
    }), */
    start_from: mapField("dateFrom", (date) => {
        return date ? formatISO(date) : null;
    }),
    end_before: mapField("dateTo", (date) => {
        return date ? formatISO(date) : null;
    }),
    rent_state_id: mapField("rentStatuses", getObjectId),
    point_id: mapField("points", getObjectId),
    pay_type_id: mapField("paymentType", Number),
    additional_service_id: mapField("additionalServices", getObjectId),
    hide_closed_rents: mapField("shouldHideClosed", Boolean),
    has_debts: mapField("hasDebts", Boolean),
    has_delivery: mapField("hasDelivery", Boolean),
    client_human_id: mapField("clientHumanId", Number),
    admin_human_id: mapField("adminHumanId", Number),
    inventory_id: mapField("inventoryId", Number),
    kit_id: mapField("kitId", Number),
    admin_user_id: mapField("adminUser", (adminUser) => adminUser.userId),
});
