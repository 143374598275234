import { mapField, getDataMapper, mapArray } from "@/shared";

export const mapInventoryIncomeFromServer = getDataMapper({
    categoryTitle: mapField("category_title", String),
    countRent: mapField("count_rent", Number),
    income: mapField("income", Number),
    inventoryTitle: mapField("inventory_title", String),
    inventoryId: mapField("inventory_id", Number),
    pointTitle: mapField("point_title", String),
});

export const mapInventoryIncomesFromServer = mapArray(
    mapInventoryIncomeFromServer
);
