import { getDataMapper, mapField, isPrimitive, isNil } from "@/shared";

export const mapIncomesFiltersToServer = getDataMapper({
    created_from: mapField("period", (period) => {
        return isPrimitive(period.interval) || isNil(period.interval)
            ? null
            : period.interval.from;
    }),
    created_to: mapField("period", (period) => {
        return isPrimitive(period.interval) || isNil(period.interval)
            ? null
            : period.interval.to;
    }),
    created_interval: mapField("period", (period) => {
        return isPrimitive(period.interval) ? period.interval : null;
    }),
    point: mapField("pointId", Number),
    employee_human_id: mapField("employee", (employee) => employee.id),
    type: mapField("type", String),
});
