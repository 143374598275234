import { getDataMapper, mapField } from "@/shared";

import { mapIncomeRepairState } from "./mapDashboardMainFromServer";

export const mapDashboardRepairFromServer = getDataMapper({
    income: mapField("income", {
        sumTotal: mapField("sum_total", Number),
    }),
    middleReceipt: mapField("middle_receipt", Number),
    countContracts: mapField("count_contracts", Number),
    incomeRepairStates: mapField("income_repair_states", mapIncomeRepairState),
    graph: mapField("graph"),
});
