/* eslint-disable node/no-unsupported-features/es-syntax */
import { REPORTS_ROUTE_NAMES } from "@/shared";
export const REPORTS_ROUTES = [
    {
        name: REPORTS_ROUTE_NAMES.REPORTS,
        path: "/report",
        meta: {
            title: "Отчеты",
        },
        component: () => import("../ui/ReportApp.vue"),
    },
    {
        name: REPORTS_ROUTE_NAMES.REPORTS_ATTENDANCE,
        path: "/report/attendance",
        meta: {
            title: "Отчет посещаемости",
        },
        component: () => import("../ui/ReportAttendance.vue"),
    },
    {
        name: REPORTS_ROUTE_NAMES.REPORTS_EFFICIENCY,
        path: "/report/efficiency",
        meta: {
            title: "Отчет эффективности",
        },
        component: () => import("../ui/ReportEfficiency.vue"),
    },
    {
        name: REPORTS_ROUTE_NAMES.REPORTS_INCOME,
        path: "/report/income",
        meta: {
            title: "Отчет доходности",
        },
        component: () => import("../ui/ReportIncome.vue"),
    },
    {
        name: REPORTS_ROUTE_NAMES.REPORTS_INVENTORY_INCOME,
        path: "/report/inventory/income",
        meta: {
            title: "Отчет доходности инвентаря",
        },
        component: () => import("../ui/ReportInventoryIncome.vue"),
    },
    {
        name: REPORTS_ROUTE_NAMES.REPORTS_INVENTORY_PAYBACK,
        path: "/report/inventory/payback",
        meta: {
            title: "Отчет окупаемости инвентаря",
        },
        component: () => import("../ui/ReportInventoryPayback.vue"),
    },
    {
        name: REPORTS_ROUTE_NAMES.REPORTS_PRODUCT_INCOME,
        path: "/report/product/income",
        meta: {
            title: "Отчет доходности магазина",
        },
        component: () => import("../ui/ReportProductIncome.vue"),
    },
    {
        name: REPORTS_ROUTE_NAMES.REPORTS_PRODUCT_LEFTOVER,
        path: "/report/product/leftover",
        meta: {
            title: "Отчет остатка",
        },
        component: () => import("../ui/ReportProductLeftover.vue"),
    },
    {
        name: REPORTS_ROUTE_NAMES.REPORTS_REVISE,
        path: "/report/revise",
        meta: {
            title: "Сверка",
        },
        component: () => import("../ui/ReportRevise.vue"),
    },
    {
        name: REPORTS_ROUTE_NAMES.REPORTS_PAYMENTS,
        path: "/report/payments",
        meta: {
            title: "Отчёт денежных поступлений",
        },
        component: () => import("../ui/ReportPayments.vue"),
    },
];
