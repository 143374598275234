import { makeHttpRequest } from "@/shared";

import { mapPaymentTypeFromServer } from "./mappers/mapPaymentTypesFromServer";

export const getPaymentType = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/payment-type/${id}`,
            method: "GET",
        })
    ).data;

    return mapPaymentTypeFromServer(response.data);
};
